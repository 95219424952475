import React from 'react';
import '../styles/jurney.css'
import Video from '../../../components/video';
import { useTranslation, Link } from 'gatsby-plugin-react-i18next';

const Jurney = () => {
  const {t} = useTranslation();

  return (
    <>
      <div className="container jurney-ctn">
        <div className="jurney-text-ctn">
          <h2>{t`register.journy-title`}</h2>
          <p className="mb-1rem" id="jurney-text">{t`register.journy-text1`}<b>{t`register.journy-text2`}</b>{t`register.journy-text3`}</p>
          <p id="go-back">
            {t`register.go-back-1a`}<Link to="/">{t`register.go-back-1b`}</Link>.
          </p>
        </div>
        <div className="jurney-video-ctn">
          <Video src="https://www.youtube.com/embed/7_p7Od6IAd4" title="Poznaj zwycięzców 3. edycji Programów Mentoringowych Innovations Hub - play.air!"/>
        </div>
      </div>
    </>
  );
}

export default Jurney
