import RegistrationPage from "../views/registration/index";
import { graphql } from 'gatsby';
// import RegistrationForm from '../views/registration-form';


export default RegistrationPage;

export const query = graphql`
  query($language: String!) {
    locales: allLocale(filter: {language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  
}`