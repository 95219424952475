import React from 'react';
import '../styles/registerOpen.css';
import { useTranslation } from 'gatsby-plugin-react-i18next';
import { StagesComponent } from './StagesComponent';

const RegisterOpen = () => {
  const { t } = useTranslation();

  return (
    <>
      <div className="container rn-header-ctn">
        <h3>{t`register-open.page-subtitle`}</h3>
        <h1>
          {t`register-open.page-title-1a`} <br />
          {t`register-open.page-title-1b`}
        </h1>
      </div>
      <div className="rn-ctn">
        <div className="container">
          <div className="rn-text-ctn">
            <h2>{t`register-open.title-rn`}</h2>
          </div>
          <a
            className="white-btn-disabled"
            target="_blank"
            rel="nofollow noreferrer">
            {t`register-open.register-now`}
          </a>
          <StagesComponent />
        </div>
      </div>
    </>
  );
};

export default RegisterOpen;
