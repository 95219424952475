import styled from 'styled-components';
import { Column, Row } from '../../../../components/global/utils';
import { Text } from '../../../../components/typography';
import { respondTo } from '../../../../utils/breakpoints';

export const StageContainer = styled(Row)`
  box-sizing: border-box;
  flex-direction: column;
  align-items: center;
  min-height: 100px;
  padding-top: 4rem;
  ${respondTo.m`
    display: flex;
    align-items: flex-start;
    justify-content: center;
    flex-direction: row;
  `}
`;

export const StageNumber = styled.div`
  min-width: 42px;
  height: 42px;
  position: relative;
  border-radius: 50px;
  margin-bottom: 5px;
  background-color: #f6f7eb;
  color: black;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  font-size: 1.5rem;
  font-family: 'Montserrat';
  font-weight: 700;
  margin-right: 1rem;

  &.active {
    background-color: #4ebfee;
    color: #f6f7eb;
  }
  .inactive {
    background-color: #f6f7eb;
    color: #060e15ff;
  }
  &.complete {
    background-color: #f6f7eb;
    color: #4ebfee;
  }

  ${respondTo.m`
    margin-right:0;
    &::after {
      content: '';
      border-top: solid 1px white;
      position: absolute;
      height: 1px;
      width: 100px;
      left: 95px;
      color: white;
    }
  `}
`;

export const StageItem = styled(Column)`
  box-sizing: border-box;
  width: 100%;
  padding: 1rem 0;
  flex-direction: row;
  justify-content: stretch !important;
  height: auto;
  &:nth-of-type(5) {
    ${StageNumber} {
      &::after {
        width: 0px;
      }
    }
  }

  ${respondTo.m`
    flex-direction: column;
    flex: 0 0 20%;
    padding: 0 10px;
  `}
`;

export const StageHeaderText = styled(Text)`
  box-sizing: border-box;
  max-width: 100%;
  color: #f6f7eb !important;
  text-align: left;
  word-break: wrap;
  ${respondTo.m`
    margin-right: 0;
    text-align:center;
  `}
`;
