import { useState } from 'react';
import React from 'react';
import {faCheck, faExclamationTriangle} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {useTranslation} from "gatsby-plugin-react-i18next";

const NewsletterForm = ( { status, message, onValidated }) => {
  const {t} = useTranslation();
  const [ error, setError ] = useState(null);
  const [ email, setEmail ] = useState(null);

  /**
   * Handle form submit.
   *
   * @return {{value}|*|boolean|null}
   */
  const handleFormSubmit = () => {

    setError(null);

    if ( ! email ) {
      setError( 'Please enter a valid email address' );
      return null;
    }

    const isFormValidated = onValidated({ EMAIL: email });

    // On success return true
    return email && email.indexOf("@") > -1 && isFormValidated;
  }

  /**
   * Handle Input Key Event.
   *
   * @param event
   */
  const handleInputKeyEvent = ( event ) => {
    setError(null);
    // Number 13 is the "Enter" key on the keyboard
    if (event.keyCode === 13) {
      // Cancel the default action, if needed
      event.preventDefault();
      // Trigger the button element with a click
      handleFormSubmit();
    }
  }

  /**
   * Extract message from string.
   *
   * @param {String} message
   * @return {null|*}
   */
  const getMessage = (message) => {
    if ( !message ) {
      return null;
    }
    const result = message?.split('-') ?? null;
    const repetition = message?.split('"') ?? null;
    const repetitionFormated = repetition?.[1]?.trim();
    const repetition2 = message?.split('jest') ?? null;
    const repetitionFormated2 = repetition2?.[0]?.trim();
    const check0 ="Dziękujemy za zapisanie się do subskrypcji!";
    const check1 ="jest już zapisany do listy";
    const check2 ="Enter a value less than 100 characters long";
    const check3 ="An email address must contain a single @";
    const check4 ="The domain portion of the email address is invalid";
    const check5 ="too many recent signup requests";
    const check6 = "The username portion of the email address is empty";
    if ( "0" !== result?.[0]?.trim() && "-1" !== message.indexOf(check0).toString() ) {
      return <div id="mc-form-label_icon"><span style={{ marginRight: '1rem' }} ><FontAwesomeIcon icon={faCheck} width="1rem" height="1rem"/></span>{t`register.newsletter-success`}</div>;
    }
    else if ( "-1" !== result?.[0]?.trim() && "-1" !== message.indexOf(check1).toString()) {
      return <div id="mc-form-label_icon"><span style={{ marginRight: '1rem' }} ><FontAwesomeIcon icon={faExclamationTriangle} width="1rem" height="1rem"/></span>{repetitionFormated2}{t`register.newsletter-error-2a`}<a href={repetitionFormated}>{t`register.newsletter-error-2b`}</a></div>;
    }
    else if ( "-1" !== message.indexOf(check2).toString()) {
      return <div id="mc-form-label_icon"><span style={{ marginRight: '1rem' }} ><FontAwesomeIcon icon={faExclamationTriangle} width="1rem" height="1rem"/></span>{t`register.newsletter-error-3`}</div>;
    }
    else if ( "-1" !== message.indexOf(check3).toString()) {
      return <div id="mc-form-label_icon"><span style={{ marginRight: '1rem' }} ><FontAwesomeIcon icon={faExclamationTriangle} width="1rem" height="1rem"/></span>{t`register.newsletter-error-4`}</div>;
    }
    else if ( "-1" !== message.indexOf(check4).toString()) {
      return <div id="mc-form-label_icon"><span style={{ marginRight: '1rem' }} ><FontAwesomeIcon icon={faExclamationTriangle} width="1rem" height="1rem"/></span>{t`register.newsletter-error-5`}</div>;
    }
    else if ( "-1" !== message.indexOf(check5).toString()) {
      return <div id="mc-form-label_icon"><span style={{ marginRight: '1rem' }} ><FontAwesomeIcon icon={faExclamationTriangle} width="1rem" height="1rem"/></span>{t`register.newsletter-error-6`}</div>;
    }
    else if ( "-1" !== message.indexOf(check6).toString()) {
      return <div id="mc-form-label_icon"><span style={{ marginRight: '1rem' }} ><FontAwesomeIcon icon={faExclamationTriangle} width="1rem" height="1rem"/></span>{t`register.newsletter-error-7`}</div>;
    }
    else if ( "0" !== result?.[0]?.trim()) {
      return <div id="mc-form-label_icon"><span style={{ marginRight: '1rem' }} ><FontAwesomeIcon icon={faExclamationTriangle} width="1rem" height="1rem"/></span>{t`register.newsletter-error-8`}</div>;
    }
    else {
      return <div id="mc-form-label_icon"><span style={{ marginRight: '1rem' }} ><FontAwesomeIcon icon={faExclamationTriangle} width="1rem" height="1rem"/></span>{t`register.newsletter-error-9`}</div>;
    }
  }

  return (
    <div>
        <div id="mc-form">
          <input
            onChange={(event) => setEmail(event?.target?.value ?? '')}
            type="email"
            placeholder={t`register.newsletter-email-address`}
            className="mc-email"
            onKeyUp={(event) => handleInputKeyEvent(event)}
          />
        </div>
        <button id="mc-form-submit" onClick={handleFormSubmit}>
          {t`register.newsletter-notify-me`}
        </button>
        <div id="mc-form-label">
          {'sending' === status ?  t(`register.newsletter-verification`) : null }
          {'error' === status || error ? getMessage(message) : null }
          {'success' === status && 'error' !== status && !error && getMessage( message )}
        </div>
    </div>
  );
}

export default NewsletterForm