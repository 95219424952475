import React, { Component } from "react";
import { useTranslation } from "gatsby-plugin-react-i18next";

class Timer extends Component {
  constructor(props) {
    super(props);
    this.countDownId = null;

    this.state = {
      days: 0,
      hours: 0,
      minutes: 0,
      seconds: 0,
    };
  }

  componentDidMount() {
    this.countDownId = setInterval(this.timerInit, 100);
  }

  componentWillUnmount() {
    if (this.countDownId) {
      clearInterval(this.countDownId);
    }
  }

  timerInit = () => {
    const { startDate } = this.props;
    const now = new Date().getTime();
    if (!startDate) {
      return;
    }
    const countDownStartDate = new Date(startDate).getTime();
    const distance = countDownStartDate - now;
    const days = Math.floor(distance / (1000 * 60 * 60 * 24));
    const hours = Math.floor(
      (distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
    );
    const minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
    const seconds = Math.floor((distance % (1000 * 60)) / 1000);

    // For countdown is finished
    if (distance < 0) {
      clearInterval(this.countDownId);
      this.setState({
        days: 0,
        hours: 0,
        minutes: 0,
        seconds: 0,
      });
      return;
    }
    this.setState({ days, hours, minutes, seconds });
  };

  render() {
    const { days, hours, minutes, seconds } = this.state;
    return (
      <div className="home-content__clock">
        <div className="top">
          <div className="time days">
            {days} <TimerTranslateText text="register.days" />
          </div>
        </div>
        <div className="time hours">
          {hours} <TimerTranslateText text="register.hours" />
        </div>
        <div className="time minutes">
          {minutes} <TimerTranslateText text="register.minutes" />
        </div>
        <div className="time seconds">
          {seconds} <TimerTranslateText text="register.seconds" />
        </div>
      </div>
    );
  }
}
export default Timer;

const TimerTranslateText = ({ text }) => {
  const { t } = useTranslation();
  return <span>{t(text)}</span>;
};
