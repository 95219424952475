import addToMailchimp from 'gatsby-plugin-mailchimp'
import NewsletterForm from './NewsletterForm';
import React from 'react';

const NewsletterSubscribe = () => {

  const [status, setStatus] = React.useState('')
  const [msg, setMsg] = React.useState('')

  const handleSubmit = async (formData) => {
    const result = await addToMailchimp(formData.EMAIL)
    setStatus(result.result)
    setMsg(result.msg)
  }

  return (

    <NewsletterForm
      status={ status }
      message={ msg }
      onValidated={ formData => handleSubmit( formData ) }
    />
  );
};

export default NewsletterSubscribe;
